export const DRAWER_TYPE_CANNED_REPLIES_UPDATE = 'canned-replies-update'
export const DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE =
  'canned-replies-change-category-update'
export const DRAWER_TYPE_CANNED_REPLIES_DELETE = 'canned-replies-delete'
export const DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE =
  'canned-reply-categories-delete'
export const DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE =
  'canned-reply-categories-update'
export const DRAWER_TYPE_CANNED_REPLIES_OVERVIEW = 'canned-replies-overview'
export const DRAWER_TYPE_FOLDERS_DELETE = 'folders-delete'
export const DRAWER_TYPE_FOLDERS_UPDATE = 'folders-update'
export const DRAWER_TYPE_FOLDERS_OVERVIEW = 'folders-overview'
export const DRAWER_TYPE_RULES_OVERVIEW = 'rules-overview'
export const DRAWER_TYPE_RULES_DELETE = 'rules-delete'
export const DRAWER_TYPE_RULES_UPDATE = 'rules-update'
export const DRAWER_TYPE_TAGS_UPDATE = 'tags-update'
export const DRAWER_TYPE_TAGS_DELETE = 'tags-delete'
export const DRAWER_TYPE_TAGS_MERGE = 'tags-merge'
export const DRAWER_TYPE_TAGS_OVERVIEW = 'tags-overview'
export const DRAWER_TYPE_USERS_OVERVIEW = 'users-overview'
export const DRAWER_TYPE_TEAMS_OVERVIEW = 'teams-overview'
export const DRAWER_TYPE_AGENT_ARCHIVE = 'agent-archive'
export const DRAWER_TYPE_AGENT_ADD = 'agent-add'
export const DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS = 'agent-add-mailbox-access'
export const DRAWER_TYPE_AGENT_OWNER = 'agent-owner'
export const DRAWER_TYPE_ADMIN_ADD = 'admin-add'
export const DRAWER_TYPE_LITE_USER_ADD = 'lite-user-add'
export const DRAWER_TYPE_REVOKE_INVITATION = 'revoke-invitation'
export const DRAWER_TYPE_UNARCHIVE = 'unarchive'
export const DRAWER_TYPE_ADMIN_REMOVE = 'admin-remove'
export const DRAWER_TYPE_USER_ADVANCED = 'user-advanced'
export const DRAWER_TYPE_TEAM_UPDATE = 'team-update'
export const DRAWER_TYPE_TEAM_DELETE = 'team-delete'
export const DRAWER_TYPE_CHANNELS_ADD = 'channels-add'
export const DRAWER_TYPE_CHANNELS_DELETE = 'channels-delete'
export const DRAWER_TYPE_CHANNELS_SELECT_EMAIL = 'channels-select-email'
export const DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE =
  'channels-select-facebookpage'
export const DRAWER_TYPE_CHANNELS_SETUP_EMAIL = 'channels-setup-email'
export const DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS = 'channels-details'
export const DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS = 'channels-forwarding'
export const DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL =
  'channels-details-oauth-modal'
export const DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH =
  'channels-details-oauth'
export const DRAWER_TYPE_CHANNELS_AUTHENTICATE = 'channels-authenticate'
export const DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP =
  'channels-authenticate-imap'
export const DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS =
  'channels-authenticate-success'
export const DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL =
  'channels-members-edit-modal'
export const DRAWER_TYPE_CHANNELS_MEMBERS_EDIT = 'channels-members-edit'
export const DRAWER_TYPE_CHANNELS_MAIL_IMPORT = 'channels-mail-import'
export const DRAWER_TYPE_CHANNELS_CREATION_FEEDBACK =
  'channels-creation-feedback'
export const DRAWER_TYPE_CHANNELS_EMAIL_FORWARD = 'channels-email-forward'
export const DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS =
  'channels-forwarding-instructions'
export const DRAWER_TYPE_CHANNELS_FORWRADING_TEST = 'channels-forwarding-test'
export const DRAWER_TYPE_CHANNELS_FORWRADING_FAILED =
  'channels-forwarding-failed'
export const DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT =
  'channels-forwarding-instructions-sent'
export const DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT = 'channels-add-live-chat'
export const DRAWER_TYPE_CHANNELS_SELECT_WIDGET = 'channels-select-widget'
export const DRAWER_TYPE_CHANNELS_CHANNEL_VOTE = 'channels-channel-vote'

export const DRAWER_TYPE_INTEGRATION_INSTALL = 'integration-install'
export const DRAWER_TYPE_INTEGRATION_UNINSTALL = 'integration-delete'
export const DRAWER_TYPE_WEBHOOKS_UPDATE = 'webhooks-update'
export const DRAWER_TYPE_WEBHOOKS_DELETE = 'webhooks-delete'
export const DRAWER_TYPE_THIRDPARTY_ACCESSTOKENS_DELETE =
  'thirdpartyaccesstokens-delete'
export const DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER =
  'integration-install-zapier'
export const DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP =
  'integration-manage-mailchimp'

export const DRAWER_TYPE_SHOPIFY_CANCEL_ORDER = 'shopify-cancel-order'
export const DRAWER_TYPE_SHOPIFY_CREATE_ORDER = 'shopify-create-order'
export const DRAWER_TYPE_SHOPIFY_SEND_INVOICE = 'shopify-send-invoice'
export const DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE = 'shopify-review-invoice'
export const DRAWER_TYPE_SHOPIFY_REFUND_ORDER = 'shopify-refund-order'
export const DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES = 'shopify-unsaved-order'
export const DRAWER_TYPE_SHOPIFY_STORE_ADD = 'shopify-store-add'
export const DRAWER_TYPE_SHOPIFY_STORE_UPDATE = 'shopify-store-update'
export const DRAWER_TYPE_SHOPIFY_CONFIGURE = 'shopify-configure'

export const DRAWER_TYPE_SPAMMERS_CREATE = 'spammers-create'
export const DRAWER_TYPE_SPAMMERS_DELETE = 'spammers-delete'

export const DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE = 'slack-configure'
export const DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE = 'slack-channel'
export const DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE =
  'slack-channel-delete'
export const DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES = 'slack-map-usernames'
export const DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS = 'slack-success'
export const DRAWER_TYPE_BILLING_CHANGE_VERSION = 'billing-change-version'
export const DRAWER_TYPE_BILLING_CONFIRM = 'billing-confirm'
export const DRAWER_TYPE_BILLING_LINK_SHOPIFY = 'billing-link-shopify'
export const DRAWER_TYPE_BILLING_EXTERNAL_APPROVE = 'billing-external-approve'
export const DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD =
  'billing-setup-payment-method'
export const DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK =
  'billing-setup-payment-method-feedback'

export const DRAWER_TYPE_EXPORTS_CANCEL_OR_DELETE = 'exports-delete'

export const DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL = 'recharge-install'
export const DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE = 'recharge-configure'
export const DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE =
  'recharge-store-update'
export const DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION =
  'recharge-cancel-subscription'
export const DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION =
  'recharge-reactivate-subscription'
export const DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER =
  'recharge-skip-next-order'

export const DRAWER_TYPE_SAMPLE = 'sample'
export const DRAWER_TYPE_KB_DELETE = 'kb-delete'
export const DRAWER_TYPE_KB_CREATE_MORE = 'kb-create-more'
export const DRAWER_TYPE_KB_ARTICLE_DELETE = 'kb-article-delete'
export const DRAWER_TYPE_KB_CATEGORY_CREATE = 'kb-category-create'

export const DRAWER_TYPE_WIDGET_DELETE = 'widget-delete'
export const DRAWER_TYPE_WIDGET_DELETE_DISABLED = 'widget-delete-disabled'
export const DRAWER_TYPE_WIDGET_REORDER_FORM_FIELDS =
  'widget-reorder-form-fields'
export const DRAWER_TYPE_WIDGET_EDIT_CUSTOM_FIELD = 'widget-edit-custom-field'
export const DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD =
  'widget-delete-custom-field'
export const DRAWER_TYPE_LIVE_CHAT_DELETE = 'live-chat-delete'
export const DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE = 'live-chat-confirm-delete'

export const DRAWER_TYPE_CUSTOMER_RATING_SETTING_UPSERT =
  'customer-rating-setting-upsert'
export const DRAWER_TYPE_CUSTOMER_RATING_SETTING_DELETE =
  'customer-rating-setting-delete'

export const DRAWER_TYPE_ANNUAL_OFFER_MODAL = 'annual-offer-modal'
export const DRAWER_TYPE_BILLING_CHANGE_PLAN = 'billing-change-plan'
export const DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN = 'billing-basic-change-plan'
export const DRAWER_TYPE_BILLING_DOWNGRADE_PLAN = 'billing-downgrade-plan'
export const DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION =
  'billing-downgrade-plan-confirmation'
export const DRAWER_TYPE_BILLING_INFORMATION = 'billing-information'

export const DRAWER_COMMENT_DELETE = 'comment-delete'
export const DRAWER_TICKET_MERGE = 'ticket-merge'

export const DRAWER_TYPE_ONBOARDING_WORKFLOW = 'onboarding-workflow'
export const DRAWER_TYPE_DISCOUNT_LOCKOUT = 'discount-lockout'

export const DRAWER_TYPE_AI_DRAFT_LOCKOUT = 'ai-draft-lockout'
export const DRAWER_TYPE_SEND_DEBUG_INFO = 'send-debug-info'

export default [
  DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS,
  DRAWER_TYPE_AGENT_ADD,
  DRAWER_TYPE_AGENT_ARCHIVE,
  DRAWER_TYPE_AGENT_OWNER,
  DRAWER_TYPE_BILLING_CHANGE_VERSION,
  DRAWER_TYPE_ADMIN_ADD,
  DRAWER_TYPE_LITE_USER_ADD,
  DRAWER_TYPE_REVOKE_INVITATION,
  DRAWER_TYPE_UNARCHIVE,
  DRAWER_TYPE_ADMIN_REMOVE,
  DRAWER_TYPE_BILLING_CONFIRM,
  DRAWER_TYPE_BILLING_LINK_SHOPIFY,
  DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD,
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK,
  DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE,
  DRAWER_TYPE_CANNED_REPLIES_DELETE,
  DRAWER_TYPE_CANNED_REPLIES_OVERVIEW,
  DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE,
  DRAWER_TYPE_CHANNELS_ADD,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS,
  DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
  DRAWER_TYPE_CHANNELS_CREATION_FEEDBACK,
  DRAWER_TYPE_CHANNELS_DELETE,
  DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
  DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS,
  DRAWER_TYPE_CHANNELS_FORWRADING_TEST,
  DRAWER_TYPE_CHANNELS_FORWRADING_FAILED,
  DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT,
  DRAWER_TYPE_CHANNELS_MAIL_IMPORT,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
  DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
  DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE,
  DRAWER_TYPE_CHANNELS_SETUP_EMAIL,
  DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT,
  DRAWER_TYPE_CHANNELS_SELECT_WIDGET,
  DRAWER_TYPE_CHANNELS_CHANNEL_VOTE,
  DRAWER_TYPE_FOLDERS_DELETE,
  DRAWER_TYPE_FOLDERS_OVERVIEW,
  DRAWER_TYPE_FOLDERS_UPDATE,
  DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER,
  DRAWER_TYPE_INTEGRATION_INSTALL,
  DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP,
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE,
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE,
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES,
  DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS,
  DRAWER_TYPE_EXPORTS_CANCEL_OR_DELETE,
  DRAWER_TYPE_INTEGRATION_UNINSTALL,
  DRAWER_TYPE_RULES_DELETE,
  DRAWER_TYPE_RULES_OVERVIEW,
  DRAWER_TYPE_RULES_UPDATE,
  DRAWER_TYPE_SAMPLE,
  DRAWER_TYPE_SHOPIFY_CANCEL_ORDER,
  DRAWER_TYPE_SHOPIFY_CONFIGURE,
  DRAWER_TYPE_SHOPIFY_CREATE_ORDER,
  DRAWER_TYPE_SHOPIFY_REFUND_ORDER,
  DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE,
  DRAWER_TYPE_SHOPIFY_SEND_INVOICE,
  DRAWER_TYPE_SHOPIFY_STORE_ADD,
  DRAWER_TYPE_SHOPIFY_STORE_UPDATE,
  DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES,
  DRAWER_TYPE_SPAMMERS_CREATE,
  DRAWER_TYPE_SPAMMERS_DELETE,
  DRAWER_TYPE_TAGS_DELETE,
  DRAWER_TYPE_TAGS_MERGE,
  DRAWER_TYPE_TAGS_OVERVIEW,
  DRAWER_TYPE_TAGS_UPDATE,
  DRAWER_TYPE_TEAM_DELETE,
  DRAWER_TYPE_TEAM_UPDATE,
  DRAWER_TYPE_TEAMS_OVERVIEW,
  DRAWER_TYPE_THIRDPARTY_ACCESSTOKENS_DELETE,
  DRAWER_TYPE_USER_ADVANCED,
  DRAWER_TYPE_USERS_OVERVIEW,
  DRAWER_TYPE_WEBHOOKS_DELETE,
  DRAWER_TYPE_WEBHOOKS_UPDATE,
  DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL,
  DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE,
  DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION,
  DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION,
  DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER,
  DRAWER_TYPE_KB_DELETE,
  DRAWER_TYPE_KB_CREATE_MORE,
  DRAWER_TYPE_KB_ARTICLE_DELETE,
  DRAWER_TYPE_KB_CATEGORY_CREATE,
  DRAWER_TYPE_WIDGET_DELETE,
  DRAWER_TYPE_WIDGET_DELETE_DISABLED,
  DRAWER_TYPE_WIDGET_REORDER_FORM_FIELDS,
  DRAWER_TYPE_WIDGET_EDIT_CUSTOM_FIELD,
  DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD,
  DRAWER_TYPE_LIVE_CHAT_DELETE,
  DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE,
  DRAWER_TYPE_CUSTOMER_RATING_SETTING_UPSERT,
  DRAWER_TYPE_CUSTOMER_RATING_SETTING_DELETE,
  DRAWER_TYPE_ANNUAL_OFFER_MODAL,
  DRAWER_TYPE_BILLING_CHANGE_PLAN,
  DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
  DRAWER_TYPE_BILLING_INFORMATION,
  DRAWER_COMMENT_DELETE,
  DRAWER_TICKET_MERGE,
  DRAWER_TYPE_ONBOARDING_WORKFLOW,
  DRAWER_TYPE_DISCOUNT_LOCKOUT,
  DRAWER_TYPE_AI_DRAFT_LOCKOUT,
  DRAWER_TYPE_SEND_DEBUG_INFO,
]
