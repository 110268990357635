import { doGraphqlRequest } from 'ducks/requests/operations'
import { FETCH_CUSTOMER_ID_REQUEST } from '../types'
import graphql from '../api'

const query = `
  query ShopifySearchCustomers(
    $query: String!
  ) {
    customers(first: 1, query: $query) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`

const doFetchCustomerId = (integrationId, email) => dispatch => {
  const variables = { query: `email:"${email}"` }

  return dispatch(
    doGraphqlRequest(FETCH_CUSTOMER_ID_REQUEST, query, variables, {
      graphqlFunc: graphql(integrationId),
      meta: {
        email,
        integrationId,
      },
    })
  ).then(data => {
    if (data?.customers?.edges?.length > 0) {
      const first = data.customers.edges[0]
      return first?.node?.id
    }
    return null
  })
}

export default doFetchCustomerId
