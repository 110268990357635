import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import { emptyObj, isEmpty } from 'util/objects'
import { emptyArr, groupByToMap } from 'util/arrays'
import { selectRequestByType } from 'ducks/requests/selectors'
import { selectResyncContactForIdAndIntegrationIdIsLoading } from 'ducks/crm/contacts/selectors/loading'
import {
  DRAFT_ORDER_CALCULATE_REQUEST,
  DRAFT_ORDER_COMPLETE_REQUEST,
  FETCH_CUSTOMER_ID_REQUEST,
} from '../types'

const byIntegrationIdCacheKey = (_state, integrationId) =>
  integrationId || 'unknown'

const byCustomerIdCacheKey = (_state, customerId) => customerId || 'unknown'

export const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.shopify || emptyObj

const selectShopInfo = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.shop || emptyObj
)

const selectShopInfoByIntegrationId = createBasicSelector(
  selectShopInfo,
  shopInfo => shopInfo.byIntegrationId || emptyObj
)

const selectShopInfoForIntegrationId = createCachedSelector(
  selectShopInfoByIntegrationId,
  (_state, integrationId) => integrationId,
  (shopInfoByIntegrationId, integrationId) =>
    shopInfoByIntegrationId[integrationId] || emptyObj
)(byIntegrationIdCacheKey)

const selectDefaultCurrencyCode = createBasicSelector(
  selectShopInfo,
  shopInfo => shopInfo.defaultCurrencyCode
)

export const selectCurrencyCode = createCachedSelector(
  selectShopInfoForIntegrationId,
  selectDefaultCurrencyCode,
  (shop, defaultCurrencyCode) => shop?.currencyCode || defaultCurrencyCode
)(byIntegrationIdCacheKey)

export const selectContactEmail = createCachedSelector(
  selectShopInfoForIntegrationId,
  shop => shop.contactEmail
)(byIntegrationIdCacheKey)

export const selectShopIsLoading = createCachedSelector(
  selectShopInfoForIntegrationId,
  shop => shop.isLoading
)(byIntegrationIdCacheKey)

export const selectShopIsLoaded = createCachedSelector(
  selectShopInfoForIntegrationId,
  shop => shop.loaded
)(byIntegrationIdCacheKey)

export const selectDraftOrders = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.draftOrders
)

export const selectDraftOrdersById = createBasicSelector(
  selectDraftOrders,
  draftOrders => draftOrders.byId || emptyObj
)

export const selectDraftOrderForId = createCachedSelector(
  selectDraftOrdersById,
  (_state, draftOrderId) => draftOrderId,
  (draftOrdersById, draftOrderId) => draftOrdersById[draftOrderId] || emptyObj
)((_state, draftOrderId) => draftOrderId || 'unknown')

export const selectDraftOrdersByCustomerId = createBasicSelector(
  selectDraftOrders,
  draftOrders => draftOrders.byCustomerId || emptyObj
)

export const selectDraftOrderForCustomerId = createCachedSelector(
  selectDraftOrdersByCustomerId,
  selectDraftOrdersById,
  (state, { customerId }) => customerId,
  (byCustomerId, byId, customerId) => {
    const draftOrderId = byCustomerId[customerId]
    if (!draftOrderId) return null

    return byId[draftOrderId]
  }
)((state, { customerId }) => customerId || 'unknown')

export const selectDraftOrderHasLineItemsForId = createCachedSelector(
  selectDraftOrderForId,
  draftOrder => {
    if (isEmpty(draftOrder)) return false

    return draftOrder?.lineItems?.length > 0
  }
)((_state, draftOrderId) => draftOrderId || 'unknown')

const selectProducts = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.products
)

const selectProductsByIntegrationId = createBasicSelector(
  selectProducts,
  products => products.byIntegrationId || emptyObj
)

const selectProductsForIntegrationId = createCachedSelector(
  selectProductsByIntegrationId,
  (_state, integrationId) => integrationId,
  (productsByIntegrationId, integrationId) =>
    productsByIntegrationId[integrationId] || emptyObj
)(byIntegrationIdCacheKey)

const selectProductsByQuery = createCachedSelector(
  selectProductsForIntegrationId,
  products => products.byQuery || emptyObj
)(byIntegrationIdCacheKey)

const selectProductsById = createCachedSelector(
  selectProductsForIntegrationId,
  products => products.byId || emptyObj
)(byIntegrationIdCacheKey)

export const selectProductsIsLoading = createCachedSelector(
  selectProductsForIntegrationId,
  products => products.isLoading
)(byIntegrationIdCacheKey)

export const selectProductsForQuery = createCachedSelector(
  selectProductsByQuery,
  selectProductsById,
  (state, integrationId) => integrationId,
  (state, _integrationId, { query }) => query,
  (byQuery, byId, _integrationId, query) => {
    const productIds = byQuery[query] || emptyArr
    return productIds.map(productId => byId[productId])
  }
)(
  (state, integrationId, { query }) =>
    `${byIntegrationIdCacheKey(state, integrationId)}-${query || 'unknown'}`
)

export const selectIsLoadedForQuery = createCachedSelector(
  selectProductsByQuery,
  (state, integrationId) => integrationId,
  (state, _integrationId, { query }) => query,
  (byQuery, _integrationId, query) => {
    return !!byQuery[query]
  }
)(
  (state, integrationId, { query }) =>
    `${byIntegrationIdCacheKey(state, integrationId)}-${query || 'unknown'}`
)

export const selectOrders = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.orders
)

export const selectOrdersLoading = createBasicSelector(
  selectOrders,
  orders => orders.isLoading
)

export const selectOrdersLoaded = createBasicSelector(
  selectOrders,
  orders => orders.isLoaded
)

export const selectOrdersByCustomerId = createBasicSelector(
  selectOrders,
  orders => orders.byCustomerId || emptyObj
)

export const selectEvents = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.events
)

export const selectCustomers = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.customers || emptyObj
)

export const selectEventsCustomerIdsByIntegrationId = createBasicSelector(
  selectEvents,
  events => events.customerIdsByIntegrationId || emptyObj
)

export const selectEventsCustomerIdsForIntegrationId = createCachedSelector(
  selectEventsCustomerIdsByIntegrationId,
  (state, integrationId) => integrationId,
  (customerIdsForIntegrationId, integrationId) =>
    customerIdsForIntegrationId[integrationId] || emptyArr
)(byIntegrationIdCacheKey)

export const selectFetchCustomerIdRequest = state => {
  return selectRequestByType(state, FETCH_CUSTOMER_ID_REQUEST)
}

export const selectEventsMetaByCustomerId = createBasicSelector(
  selectEvents,
  events => events.metaByCustomerId || emptyObj
)

export const selectEventsIsLoadingForIntegrationId = createCachedSelector(
  selectEventsCustomerIdsForIntegrationId,
  selectEventsMetaByCustomerId,
  (eventsCustomerIdsForIntegrationId, metaByCustomerId) => {
    const loadingStates = []

    eventsCustomerIdsForIntegrationId.forEach(customerId => {
      loadingStates.push(metaByCustomerId[customerId]?.isLoading || false)
    })

    return loadingStates.some(loading => loading)
  }
)(byIntegrationIdCacheKey)

export const selectIsLoading = createCachedSelector(
  selectOrdersLoading,
  selectOrdersLoaded,
  selectEventsIsLoadingForIntegrationId,
  selectShopIsLoading,
  selectResyncContactForIdAndIntegrationIdIsLoading,
  selectFetchCustomerIdRequest,
  (
    ordersLoading,
    ordersLoaded,
    eventsLoading,
    shopLoading,
    resyncLoading,
    { loading: fetchCustomerIdLoading = false }
  ) =>
    (ordersLoading && !ordersLoaded) ||
    eventsLoading ||
    shopLoading ||
    resyncLoading ||
    fetchCustomerIdLoading
)(
  (state, integrationId, contactId) =>
    `${integrationId || 'unknown'}-${contactId || 'unknown'}`
)

export const selectEventsByCustomerId = createBasicSelector(
  selectEvents,
  events => events.byCustomerId || emptyObj
)

export const selectEventsForCustomerId = createCachedSelector(
  selectEventsByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => {
    const forCustomerId = byCustomerId[customerId]
    return forCustomerId
  }
)(byCustomerIdCacheKey)

export const selectEventsMetaForCustomerId = createCachedSelector(
  selectEventsMetaByCustomerId,
  (state, customerId) => customerId,
  (metaByCustomerId, customerId) => {
    const forCustomerId = metaByCustomerId[customerId]
    return forCustomerId
  }
)(byCustomerIdCacheKey)

export const selectEventsForCustomerIdGroupedByDay = createCachedSelector(
  selectEventsByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => {
    const forCustomerId = byCustomerId[customerId] || []
    return groupByToMap(forCustomerId, ({ date }) => date)
  }
)(byCustomerIdCacheKey)

export const selectOrdersForCustomerId = createCachedSelector(
  selectOrdersByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => {
    const forCustomerId = byCustomerId[customerId]
    return forCustomerId
  }
)(byCustomerIdCacheKey)

export const selectOrdersById = createSelector(
  selectOrdersByCustomerId,
  byCustomerId => {
    const byId = {}
    Object.keys(byCustomerId).forEach(customerId => {
      Object.values(byCustomerId[customerId]).forEach(order => {
        byId[order.id] = order
      })
    })
    return byId
  }
)

export const selectOrderForId = createCachedSelector(
  selectOrdersById,
  (state, orderId) => orderId,
  (byId, orderId) => {
    return byId[orderId]
  }
)((state, orderId) => orderId || 'unknown')

const selectPaymentTermTemplates = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.paymentTermsTemplates || emptyObj
)

const selectPaymentTermTemplatesByIntegrationId = createBasicSelector(
  selectPaymentTermTemplates,
  paymentTermTemplates => paymentTermTemplates.byIntegrationId || emptyObj
)

export const selectPaymentTermTemplatesIsLoading = createCachedSelector(
  selectPaymentTermTemplatesByIntegrationId,
  (_state, integrationId) => integrationId,
  (paymentTermTemplatesByIntegrationId, integrationId) =>
    paymentTermTemplatesByIntegrationId[integrationId]?.isLoading
)(byIntegrationIdCacheKey)

export const selectPaymentTermTemplatesIsLoaded = createCachedSelector(
  selectPaymentTermTemplatesByIntegrationId,
  (_state, integrationId) => integrationId,
  (paymentTermTemplatesByIntegrationId, integrationId) =>
    paymentTermTemplatesByIntegrationId[integrationId]?.loaded || false
)(byIntegrationIdCacheKey)

export const selectPaymentTermTemplatesHasError = createCachedSelector(
  selectPaymentTermTemplatesByIntegrationId,
  (_state, integrationId) => integrationId,
  (paymentTermTemplatesByIntegrationId, integrationId) =>
    paymentTermTemplatesByIntegrationId[integrationId]?.error || false
)(byIntegrationIdCacheKey)

export const selectPaymentTermTemplatesForIntegrationId = createCachedSelector(
  selectPaymentTermTemplatesByIntegrationId,
  (_state, integrationId) => integrationId,
  (paymentTermTemplatesByIntegrationId, integrationId) =>
    paymentTermTemplatesByIntegrationId[integrationId]?.templates || emptyArr
)(byIntegrationIdCacheKey)

export const selectDraftOrderCalculateRequest = state => {
  return selectRequestByType(state, DRAFT_ORDER_CALCULATE_REQUEST)
}

export const selectDraftOrderCompleteRequest = state => {
  return selectRequestByType(state, DRAFT_ORDER_COMPLETE_REQUEST)
}

export const selectCustomerIdByIntegrationIdAndEmail = createCachedSelector(
  selectCustomers,
  (_state, integrationId) => integrationId,
  (_state, _integrationId, email) => email,
  (customers, integrationId, email) => {
    const key = `${integrationId}-${email}`

    if (
      customers.byIntegrationIdAndEmail &&
      customers.byIntegrationIdAndEmail[key]
    ) {
      return customers.byIntegrationIdAndEmail[key]
    }

    return {
      found: false,
      integrationId,
      loaded: false,
    }
  }
)(
  (_state, integrationId, email) =>
    `${integrationId || 'unknown'}-${email || 'unknown'}`
)

export const selectOrdersMetaByCustomerId = createBasicSelector(
  selectOrders,
  orders => orders.metaByCustomerId || emptyObj
)

export const selectOrdersMetaForCustomerId = createCachedSelector(
  selectOrdersMetaByCustomerId,
  (_state, customerId) => customerId,
  (metaByCustomerId, customerId) => {
    const forCustomerId = metaByCustomerId[customerId]
    return forCustomerId || emptyObj
  }
)(byCustomerIdCacheKey)
