import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectCurrentUserId } from 'ducks/currentUser/selectors'
import { selectAgentCount } from 'selectors/agents/base'
import {
  selectPendingEntityById,
  selectCurrentEntityById,
  selectCurrentEntityIds,
  selectCurrentEntitiesSortedBy,
  selectCurrentEntitiesById,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'
import { selectInaccessibleMailboxesIds } from 'selectors/mailboxes/selectInaccessibleMailboxesIds'
import {
  selectCurrentPath,
  isLiveChatPath,
  isSocialChatPath,
  isInboxPath,
} from 'selectors/location'
import { getChannelTypeByTypeValue, getRestrictionType } from './utils'
import { isEmailChannelType } from './channelTypes'

export const selectPendingChannelById = (state, id) =>
  selectPendingEntityById(state, 'channel', id) || null

export const selectCurrentChannelById = (state, id) =>
  selectCurrentEntityById(state, 'channel', id) || null

export const selectCurrentChannelsById = state =>
  selectCurrentEntitiesById(state, 'channel') || null

export const selectCurrentChannels = state =>
  selectCurrentEntitiesSortedBy(state, 'channel', 'position', 'asc') || emptyArr

export const selectAccessibleCurrentEmailChannels = createSelector(
  selectCurrentChannels,
  selectInaccessibleMailboxesIds,
  (currentChannels, inaccessibleMailboxesIds) => {
    return currentChannels.filter(
      c =>
        isEmailChannelType(getChannelTypeByTypeValue(c.channel_type)) &&
        !inaccessibleMailboxesIds.includes(c.id)
    )
  }
)

export const selectCurrentChannelRestriction = createCachedSelector(
  selectCurrentChannelById,
  selectCurrentUserId,
  (channel, currentUserId) => getRestrictionType(channel, currentUserId)
)((_state, channelId) => channelId)

export const selectCurrentChannelCount = state => {
  return (selectCurrentEntityIds(state, 'channel') || emptyArr).length
}

export const selectChannelTypeFromUrl = createSelector(
  selectCurrentPath,
  path => {
    if (isInboxPath(path)) return 'mailbox'
    if (isLiveChatPath(path)) return 'widget'
    if (isSocialChatPath(path)) return 'social'

    return null
  }
)

export const selectChannelCreationStepCount = createCachedSelector(
  selectAgentCount,
  (_state, channelConfig) => channelConfig,
  (activeAgentCount, channelConfig) => {
    const { totalStepCount } = channelConfig || {}
    if (!totalStepCount) return 0
    return activeAgentCount > 1 ? totalStepCount : totalStepCount - 1
  }
)((_state, channelConfig) => channelConfig?.name || 'unknown')
