import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerForCustomerEmail } from 'ducks/integrations/stripe/selectors/customers'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'

import doFetchCustomerByEmail from 'ducks/integrations/stripe/operations/doFetchCustomerByEmail'

export const useCustomer = integrationId => {
  const dispatch = useDispatch()
  const email = useSelector(selectCurrentContactOrCustomerEmail)

  const customerData = useSelector(state =>
    selectCustomerForCustomerEmail(state, integrationId, { email })
  )

  useEffect(
    () => {
      if (integrationId && email && !customerData.isInitialized) {
        dispatch(doFetchCustomerByEmail({ integrationId, email }))
      }
    },
    [dispatch, integrationId, email, customerData]
  )

  if (customerData) {
    return customerData
  }

  return {}
}
