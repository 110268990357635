import { createActionTypeReducer } from 'util/reducers'

import {
  FETCH_CUSTOMER_ID_REQUEST_SUCCESS,
  FETCH_CUSTOMER_ID_REQUEST_FAILED,
} from '../types'

const initialState = {
  byIntegrationIdAndEmail: {},
}

export default createActionTypeReducer(
  {
    [FETCH_CUSTOMER_ID_REQUEST_FAILED]: (draftState, action) => {
      const { meta: { email, integrationId } = {} } = action || {}

      const key = `${integrationId}-${email}`

      if (!draftState.byIntegrationIdAndEmail[key])
        draftState.byIntegrationIdAndEmail[key] = {}

      draftState.byIntegrationIdAndEmail[key].found = false
      draftState.byIntegrationIdAndEmail[key].integrationId = integrationId
      draftState.byIntegrationIdAndEmail[key].loaded = true

      return draftState
    },
    [FETCH_CUSTOMER_ID_REQUEST_SUCCESS]: (draftState, action) => {
      const {
        meta: { email, integrationId } = {},
        payload: { customers: { edges = [] } = {} } = {},
      } =
        action || {}

      const { node } = edges[0] || {}

      const key = `${integrationId}-${email}`

      if (!draftState.byIntegrationIdAndEmail[key])
        draftState.byIntegrationIdAndEmail[key] = {}

      draftState.byIntegrationIdAndEmail[key].found = !!node
      draftState.byIntegrationIdAndEmail[key].integrationId = integrationId
      draftState.byIntegrationIdAndEmail[key].loaded = true
      if (node) {
        Object.assign(draftState.byIntegrationIdAndEmail[key], node)
      }

      return draftState
    },
  },
  initialState
)
