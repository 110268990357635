import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import FlashIcon from '@groovehq/internal-design-system/lib/assets/icons/Flash'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  doSelectReply,
  doToggleCannedReplyDropdown,
} from 'ducks/cannedReplies/operations'

import {
  selectCannedReplyTemplates,
  selectFlatCannedReplyCategoriesWithTemplates,
} from 'ducks/cannedReplies/selectors'

import storage from 'util/storage'
import { uniq } from 'util/arrays'

import ListenToKeyboard from 'components/ListenToKeyboard'
import NavItem from 'components/App/DesktopView/shared/Composer/Email/Header/NavItem'
import SizerContext from 'components/App/DesktopView/CommunicationForms/shared/Sizer/Context'
import { HIDE_CANNED_REPLY_PREVIEW_CALLOUT } from 'ducks/flags/flagNames'
import { selectFlag } from 'ducks/flags/selectors'
import {
  selectIsCannedReplyDropdownVisible,
  selectCannedReplyDropdownSource,
  selectCannedReplyDropdownCursorBookmark,
} from 'selectors/app/base'
import editor from 'shared/editor/utils'

import { styles } from './styles'
import DropdownMenuWithPreview from './DropdownMenuWithPreview'
import WithPreviewCallout from './WithPreviewCallout'

const heightConstraints = {
  max: 480,
  min: 430,
}

const RECENTLY_USED_CANNED_REPLY_TEMPLATES =
  'recently_used_canned_reply_templates'

const getTemplateToRecentlyUsed = () => {
  const storedTemplatesIdsString = storage.get(
    RECENTLY_USED_CANNED_REPLY_TEMPLATES
  )
  return storedTemplatesIdsString ? JSON.parse(storedTemplatesIdsString) : []
}

const saveTemplateToRecentlyUsed = id => {
  const storedTemplatesIds = getTemplateToRecentlyUsed()
  storedTemplatesIds.unshift(id)
  storage.set(
    RECENTLY_USED_CANNED_REPLY_TEMPLATES,
    JSON.stringify(uniq(storedTemplatesIds).slice(0, 20))
  )
}

const CannedReplyDropdown = ({ ...props }) => {
  const dispatch = useDispatch()
  const [recentlyUsedTemplates, setRecentlyUsedTemplates] = useState([])
  const { scrollIntoView: scrollEditorIntoView } = useContext(SizerContext)
  const items = useSelector(selectFlatCannedReplyCategoriesWithTemplates)
  const templates = useSelector(selectCannedReplyTemplates)
  const shouldDisableTooltip = !useSelector(state =>
    selectFlag(state)(HIDE_CANNED_REPLY_PREVIEW_CALLOUT)
  )
  const cannedReplyDropdownVisible = useSelector(
    selectIsCannedReplyDropdownVisible
  )
  const cannedReplyDropdownSource = useSelector(selectCannedReplyDropdownSource)
  const cannedReplyDropdownCursorBookmark = useSelector(
    selectCannedReplyDropdownCursorBookmark
  )

  useEffect(
    () => {
      if (cannedReplyDropdownVisible) {
        const storedTemplatesIds = getTemplateToRecentlyUsed()
        // Filter out templates that have been deleted
        const templatesIds = storedTemplatesIds.filter(id =>
          templates.some(template => template.id === id)
        )
        setRecentlyUsedTemplates(
          templatesIds
            .slice(0, 4)
            .map(id => templates.find(template => template.id === id))
        )
      }
    },
    [cannedReplyDropdownVisible, templates]
  )

  const handleVisibleChange = useCallback(
    visible => {
      dispatch(doToggleCannedReplyDropdown(visible))

      if (!visible && cannedReplyDropdownSource === 'forward-slash') {
        const tinyEditor = editor.getEditor()
        tinyEditor.selection.moveToBookmark(cannedReplyDropdownCursorBookmark)
      }
    },
    [dispatch, cannedReplyDropdownSource, cannedReplyDropdownCursorBookmark]
  )

  const onSelect = useCallback(
    item => {
      dispatch(doSelectReply(item))
      dispatch(doToggleCannedReplyDropdown(false))
      saveTemplateToRecentlyUsed(item?.id)
    },
    [dispatch]
  )

  const handleI = useCallback(
    () => {
      scrollEditorIntoView(true)
      setTimeout(() => {
        dispatch(doToggleCannedReplyDropdown(true))
      }, 1)
    },
    [dispatch, scrollEditorIntoView]
  )

  const overlay = useMemo(
    () => (
      <DropdownMenuWithPreview
        onSelect={onSelect}
        items={items}
        recentlyUsedTemplates={recentlyUsedTemplates}
        visible={cannedReplyDropdownVisible}
        onVisibleChange={handleVisibleChange}
      />
    ),
    [
      onSelect,
      items,
      recentlyUsedTemplates,
      handleVisibleChange,
      cannedReplyDropdownVisible,
    ]
  )

  return (
    <>
      <Dropdown
        overlay={overlay}
        css={[text.styles.textSm, styles.dropdownContainer]}
        visible={cannedReplyDropdownVisible}
        onVisibleChange={handleVisibleChange}
        emptyHint=""
        portal="#overlays"
        strategy="fixed"
        heightConstraints={heightConstraints}
        position={window.innerHeight < 790 ? 'auto' : 'bottom-start'}
        isNavByArrowsDisabled
      >
        <div>
          <ListenToKeyboard
            onI={handleI}
            preventDefault
            disableForInput
            key="i"
            enabled={!cannedReplyDropdownVisible}
          />
          <Tooltip
            title={`Insert ${app.t('canned_reply_with_article')} [i]`}
            strategy="fixed"
            disabled={cannedReplyDropdownVisible || shouldDisableTooltip}
          >
            <NavItem active {...props}>
              <div className="grui flex-1-0-auto flex-center">
                <FlashIcon css={styles.iconStyle} />
                <WithPreviewCallout>
                  <div className="grui ml-2" css={styles.buttonStyle}>
                    {app.t('Canned_replies')}
                  </div>
                </WithPreviewCallout>
              </div>
            </NavItem>
          </Tooltip>
        </div>
      </Dropdown>
    </>
  )
}

export default CannedReplyDropdown
